import React from "react"

import SEO from "../components/seo"
import OwnersImage from "../components/ownersImage"
import styles from "./contact.module.css";

import Header from "../components/header";
import Layout, {designSystem} from "../components/layout";
import headerStyles from "../components/header.module.css";
import ContactSection from "../components/contactSection";
import SocialMedia from "../components/socialMedia";


const Contact = () => (
        <Layout>
            <div
                className={styles.all}
            >
                <SEO title="Contact"/>
                <div
                    className={headerStyles.header}
                >
                    <Header
                        headerTextColour={designSystem.palette.black}
                        backgroundColour={designSystem.palette.white}
                        backgroundColourSmallScreen={designSystem.palette.white}
                        showSmallHeading={true}
                    />
                </div>
                <div
                    className={styles.content}
                >
                    <h2>Contact The Lavender Ladies</h2>

                    <div
                        className={styles.smallImage}
                    >
                        <div
                            className={styles.imageWrapper}>
                            <OwnersImage/>
                        </div>
                    </div>

                    <blockquote>We are open 9 - 5, 7 days a week. We would love to see you in store, otherwise please feel
                        free to contact us on our details below.
                    </blockquote>

                    <div
                        className={styles.contentContainer}
                    >
                        <div
                            style={{
                                width: '100%'
                            }}
                        >
                            <ContactSection/>

                        </div>
                        <div
                            className={styles.ownersImageLargeDevices}
                        >
                            <OwnersImage
                            />
                        </div>
                    </div>
                </div>
                <SocialMedia/>
            </div>
        </Layout>
    )
;

export default Contact;